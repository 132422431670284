<template>
  <el-container id="app">
    <el-main>

      <div class="logo"><img src="./assets/logo.png" /></div>

      <el-row :gutter="40" id="projects">
        <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="project in projects" v-bind:key="project.name">
          <el-card shadow="hover">
            <a :href="project.uri" target="_blank">
              <img :src="project.logo" class="image">
              <div style="padding: 14px;">
                <span>{{ project.name }}</span>
              </div>
            </a>
          </el-card>
        </el-col>
      </el-row>

    </el-main>
    <el-footer>
      <el-link :underline="false" href="https://twitter.com/mycodecrafting" target="_blank"><i class="fab fa-twitter" style="color: #1DA1F2"></i></el-link> Built by Codecrafting Labs. Made with <i class="fas fa-heart" style="color: #F64947"></i>
    </el-footer>
  </el-container>
</template>

<script>
const logos = {
  dogami: require('./assets/projects/dogami.png'),
  ziggurats: require('./assets/projects/ziggurats.png'),
  ottez: require('./assets/projects/ottez.png'),
  ottezEvolution: require('./assets/projects/ottez-evolution.png'),
  tezzardz: require('./assets/projects/tezzardz.png'),
  prjktneon: require('./assets/projects/prjktneon.png'),
  gogo: require('./assets/projects/gogo.jpg'),
  neonz: require('./assets/projects/neonz.jpg'),
  themoments: require('./assets/projects/themoments.jpg'),
  chopsumo: require('./assets/projects/chopsumo.png'),
  owlz: require('./assets/projects/owlz.png'),
  cybergeckogang: require('./assets/projects/cybergeckogang.png'),
  tezapegang: require('./assets/projects/tezapegang.png'),
  glogang: require('./assets/projects/glogang.jpg'),
  cyberkidzclub: require('./assets/projects/cyberkidzclub.png'),
  fluxtribe: require('./assets/projects/fluxtribe.png'),
  tacodudez: require('./assets/projects/tacodudez.png'),
};

export default {
  name: 'app',
  data() {
    return {
      projects: [
        { name: "Chop Sumo", logo: logos.chopsumo, uri: 'https://chopsumo.gallery/' },
        { name: "CyberGeckoGang", logo: logos.cybergeckogang, uri: 'https://cybergeckogang.gallery/' },
        { name: "CyberKidz Club", logo: logos.cyberkidzclub, uri: 'https://cyberkidzclub.gallery/' },
        { name: "Dogami", logo: logos.dogami, uri: 'https://dogami.gallery/' },
        { name: "FLUX Tribe", logo: logos.fluxtribe, uri: 'https://fluxtribe.gallery/' },
        { name: "GloGang", logo: logos.glogang, uri: 'https://glogang.rarity.gallery/' },
        { name: "GOGOs", logo: logos.gogo, uri: 'https://gogos.gallery/' },
        { name: "NEONZ", logo: logos.neonz, uri: 'https://neonz.gallery/' },
        { name: "Ottez", logo: logos.ottez, uri: 'https://ottez.gallery/' },
        { name: "Ottez Evolution", logo: logos.ottezEvolution, uri: 'https://evolution.ottez.gallery/' },
        { name: "Owlz", logo: logos.owlz, uri: 'https://owlz.live/' },
        { name: "PRJKTNEON", logo: logos.prjktneon, uri: 'https://prjkt.gallery/' },
        { name: "TacoDudez", logo: logos.tacodudez, uri: 'https://tacodudez.gallery/' },
        { name: "TezApeGang", logo: logos.tezapegang, uri: 'https://tezapegang.gallery/' },
        { name: "Tezzardz", logo: logos.tezzardz, uri: 'https://fukkrs.gallery/' },
        { name: "The Moments", logo: logos.themoments, uri: 'https://themoments.gallery/' },
        { name: "Ziggurats", logo: logos.ziggurats, uri: 'https://ziggurats.xyz/#/gallery' },
      ]
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scss>
html,
body {
  margin: 0;
  height: 100%;
  background: #1b0f2e;
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #fff;
  font-size: 16px;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
}

.logo {
  text-align: center;
  margin: 50px 0;
}
.logo img {
  max-width: 800px;
}

#projects .el-card {
    background: transparent;
    padding: 0px;
    border: none;
    text-align: center;
    color: #43dfe3;
}

#projects a,
#projects a:hover,
#projects a:focus,
#projects a:visited {
    color: #43dfe3;
    text-decoration: none;
}

#projects .el-card.is-hover-shadow:hover,
#projects .el-card.is-hover-shadow:focus {
    box-shadow: none;
}

#projects .el-card .image {
    width: -webkit-fill-available;
    border-radius: 50%;
    box-shadow: 0 2px 12px 2px rgb(0 0 0 / 100%);
}

#projects .el-card.is-hover-shadow .el-card__body a:hover .image,
#projects .el-card.is-hover-shadow .el-card__body a:focus .image {
    box-shadow: 0 2px 24px 2px rgb(155 99 245 / 85%);
}

.el-footer {
    text-align: center;
    margin-top: 60px;
    padding: 20px 0;
}

@media only screen and (max-width: 991px) {
  .logo img {
    width: 100%;
    max-width: 600px;
  }
}

</style>
